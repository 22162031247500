import { styled } from '@linaria/react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size: number | string;
  axis?: 'horizontal' | 'vertical';
}

function Spacer({ size, axis, style = {}, ...delegated }: Props) {
  return (
    <Wrapper
      {...delegated}
      style={{
        minWidth: axis !== 'vertical' ? size : undefined,
        minHeight: axis !== 'horizontal' ? size : undefined,
        ...style,
      }}
    />
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Spacer;
